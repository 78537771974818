const data = [
    {
      mainImage: 'assets/image1.png',
      thumbnail1: 'assets/thumb1.png',
      thumbnail2: 'assets/thumb2.png',
      thumbnail3: 'assets/thumb3.png',
      description: 'This is the first description with some details about the image.',
      price: '19.99',
    },
    {
      mainImage: 'assets/image2.png',
      thumbnail1: 'assets/5555-all-over-print-unisex-hoodie-white-front-644f3e9976610.png',
      thumbnail2: 'assets/5555-all-over-print-unisex-hoodie-white-back-644f3e9976809.png',
      thumbnail3: 'assets/thumb6.png',
      description: 'This is the second description with some details about the image.',
      price: '29.99',
    },
    {
      mainImage: 'assets/image3.png',
      thumbnail1: 'assets/5555-all-over-print-unisex-hoodie-white-front-644f3e9976610.png',
      thumbnail2: 'assets/5555-all-over-print-unisex-hoodie-white-back-644f3e9976809.png',
      thumbnail3: 'assets/thumb6.png',
      description: 'This is the second description with some details about the image.',
      price: '29.99',
    },
    {
      mainImage: 'assets/gymbag/gymbag01-all-over-print-gym-bag-white-left-front-6686c5fa0f153.jpg',
      thumbnail1: 'assets/gymbag/gymbag01-all-over-print-gym-bag-white-front-6686c5fa0e836.jpg',
      thumbnail2: 'assets/gymbag/gymbag01-all-over-print-gym-bag-white-back-6686c5fa0ed12.jpg',
      thumbnail3: 'assets/gymbag/gymbag01-all-over-print-gym-bag-white-right-front-6686c5fa0ef21.jpg',
      description: 'Our first edition canvas gym bag',
      price: '101',
    },
    {
      mainImage: 'assets/crossbodybag/01_all-over-print-crossbody-bag-black-product-details-2-66b569eeb6c3a.jpg',
      thumbnail1: 'assets/crossbodybag/01_all-over-print-crossbody-bag-black-front-66b569eeb6816.jpg',
      thumbnail2: 'assets/crossbodybag/01_all-over-print-crossbody-bag-black-front-66b569eeb6403.jpg',
      thumbnail3: 'assets/crossbodybag/01_all-over-print-crossbody-bag-black-back-66b569eeb69b7.jpg',
      description: 'Our first edition cross body bag - #01',
      price: '101',    
    },
    {
      mainImage: 'assets/crossbodybag/02_all-over-print-crossbody-bag-black-front-66b5695e184bc.jpg',
      thumbnail1: 'assets/crossbodybag/02_all-over-print-crossbody-bag-black-product-details-66b5695e188c4.jpg',
      thumbnail2: 'assets/crossbodybag/02_all-over-print-crossbody-bag-black-front-66b5695e18709.jpg',
      thumbnail3: 'assets/crossbodybag/02_all-over-print-crossbody-bag-black-product-details-2-66b5695e187eb.jpg',
      description: 'Our first edition cross body bag - #02',
      price: '101',
    },
    {
      mainImage: 'assets/crossbodybag/03_all-over-print-crossbody-bag-white-front-66b56990096ba.jpg',
      thumbnail1: 'assets/crossbodybag/03_all-over-print-crossbody-bag-white-product-details-2-66b569900994e.jpg',
      thumbnail2: 'assets/crossbodybag/03_all-over-print-crossbody-bag-white-front-66b56990098b8.jpg',
      thumbnail3: 'assets/crossbodybag/03_all-over-print-crossbody-bag-white-product-details-66b56990099fc.jpg',
      description: 'Our first edition cross body bag - #03',
      price: '101',
    },
    // Add more entries as needed
  ];
  
  export default data;
  



   
   
   
   
   
  
   