import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './App.css';
import data from './data';

// Function to generate random soft color
const getRandomSoftColor = () => {
  const letters = '89ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

// Function to generate random gradient
const getRandomGradient = () => {
  const color1 = getRandomSoftColor();
  const color2 = getRandomSoftColor();
  return `linear-gradient(45deg, ${color1}, ${color2})`;
};

// Function to get random data set
const getRandomDataSet = () => {
  const index = Math.floor(Math.random() * data.length);
  return data[index];
};

function App() {
  const [pageData, setPageData] = useState({});
  const [bgGradient, setBgGradient] = useState('');
  const [image, setImage] = useState('');
  const [direction, setDirection] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');

  useEffect(() => {
    // Initialize with the first page data
    if (data.length > 0) {
      const initialData = getRandomDataSet();
      setPageData(initialData);
      setImage(initialData.mainImage);
      setDescription(initialData.description);
      setPrice(initialData.price);
      setBgGradient(getRandomGradient());
    }

    // Add event listener for keyboard arrow keys
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowLeft':
          changeContent('left');
          break;
        case 'ArrowRight':
          changeContent('right');
          break;
        case 'ArrowUp':
          changeContent('top');
          break;
        case 'ArrowDown':
          changeContent('bottom');
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const changeContent = (newDirection) => {
    const newDataSet = getRandomDataSet();
    setPageData(newDataSet);
    setImage(newDataSet.mainImage);
    setDescription(newDataSet.description);
    setPrice(newDataSet.price);
    setBgGradient(getRandomGradient());
    setDirection(newDirection);
  };

  const handleImageClick = (img) => {
    setImage(img);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => changeContent('left'),
    onSwipedRight: () => changeContent('right'),
    onSwipedUp: () => changeContent('bottom'),
    onSwipedDown: () => changeContent('top'),
  });

  return (
    <div {...handlers} className={`App ${direction}`}>
      <nav className="navbar">
        <span>Rickshaw Club Beta</span>
      </nav>
      <TransitionGroup>
        <CSSTransition key={bgGradient} classNames={direction} timeout={500}>
          <div className="color-panel" style={{ background: bgGradient }}>
            <div className="canvas-container">
              <button className="left-button" onClick={() => changeContent('left')}> Left </button>
              <button className="top-button" onClick={() => changeContent('top')}> Top </button>
              <button className="right-button" onClick={() => changeContent('right')}> Right </button>
              <button className="bottom-button" onClick={() => changeContent('bottom')}> Bottom </button>
              <div className="canvas">
                <img src={image} alt="Main" className="canvas-image" />
              </div>
              <div className="small-canvas">
                {pageData.thumbnail1 && (
                  <div className="thumbnail" onClick={() => handleImageClick(pageData.thumbnail1)}>
                    <img src={pageData.thumbnail1} alt="Thumbnail 1" className="thumbnail-image" />
                  </div>
                )}
                {pageData.thumbnail2 && (
                  <div className="thumbnail" onClick={() => handleImageClick(pageData.thumbnail2)}>
                    <img src={pageData.thumbnail2} alt="Thumbnail 2" className="thumbnail-image" />
                  </div>
                )}
                {pageData.thumbnail3 && (
                  <div className="thumbnail" onClick={() => handleImageClick(pageData.thumbnail3)}>
                    <img src={pageData.thumbnail3} alt="Thumbnail 3" className="thumbnail-image" />
                  </div>
                )}
              </div>
              <div className="description-canvas">
                <p className="description">{description}</p>
                <p className="price">${price}</p>
              </div>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default App;
